<template>
	<div>
		<div ref="exEketelonItem" class="invisible">
			<ExerciseItem
				:exercise="{ _id: 'sketelon', name: 'sletelon', status: 'locked', usersScores: [] }"
				:isLast="true"
				:formation="formation"
			/>
		</div>
		<ExerciseItem
			v-for="(exercise, index) in openOngoingAndPassedExercises"
			:key="exercise._id"
			:exercise="exercise"
			:isLast="index === openOngoingAndPassedExercises.length - 1"
			:setLoading="setLoading"
			:formation="formation"
		/>
		<Loading :show="loading" label="3, 2, 1..."></Loading>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from 'vue-full-loading';

import ExerciseItem from './ExerciseItem';
import { ExerciseStatus } from '@/constants/exercise.js';
import { setWidthForExerciseItems } from '@/utils/css-util.js';

export default {
	name: 'ListExercices',
	components: { Loading, ExerciseItem },
	data() {
		return { loading: false, openOngoingAndPassedExercises: [] };
	},
	props: { exercises: Array },
	created() {
		const sortedDescPassedEx = this.exercises
			.filter((exercise) => exercise.status === ExerciseStatus.PASSED)
			.sort((a, b) => b.position_list - a.position_list);
		const openAndOngoing = this.exercises.filter((exercise) =>
			[ExerciseStatus.OPEN, ExerciseStatus.ONGOING].includes(exercise.status)
		);
		this.openOngoingAndPassedExercises = [...openAndOngoing, ...sortedDescPassedEx];
	},
	mounted() {
		this.$nextTick(() => {
			setWidthForExerciseItems(); // Run after the rendering is completed
			const element = this.$refs.exEketelonItem;
			if (element) element.parentNode.removeChild(element);
		});
	},
	computed: {
		...mapGetters('profile', { formation: 'formation' }),
	},
	methods: {
		setLoading(value) {
			this.loading = value;
		},
	},
};
</script>

<style lang="scss" scoped></style>
