<template>
	<div>
		<div class="pb-3 mb-9">
			<ChapterDetailHeader :selectingCategory="selectingCategory" />
		</div>
		<ListExercices v-if="selectingCategory" :exercises="selectingCategory.exercises" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import ListExercices from '../exercise/ListExercices';
import ChapterDetailHeader from './ChapterDetailHeader';

export default {
	name: 'ChapterDetail',
	components: {
		ChapterDetailHeader,
		ListExercices,
	},
	data() {
		return {
			selectingCategory: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	mounted() {
		this.getParcoursInFormationProgress(this.$route.params.idParcours);
	},
	watch: {
		formationProgress() {
			this.getParcoursInFormationProgress(this.$route.params.idParcours);
		},
		'$route.params.idParcours'(newId, oldId) {
			if (newId === oldId) return;
			else this.getParcoursInFormationProgress(newId);
		},
	},
	methods: {
		getParcoursInFormationProgress(idParcours) {
			if (this.formationProgress == null) return null;
			const currentParcoursItem = this.formationProgress.config.parcours_list.find(
				(item) => item.parcours_id._id === idParcours
			);
			if (currentParcoursItem == null) return;
			this.getCategoryInParcours(currentParcoursItem.parcours_id, this.$route.params.idCategory);
		},
		getCategoryInParcours(parcours, idCategory) {
			const selectingCategory = parcours.categories.find((category) => category._id === idCategory);
			this.selectingCategory = selectingCategory;
		},
	},
};
</script>

<style lang="scss" scoped></style>
