<template>
	<div class="exercise-card-item d-flex">
		<v-progress-circular
			class="progressCircle"
			:rotate="-90"
			:class="`${verticalLineBackground} ${isLast === false && 'verticalLine'}`"
			:width="7"
			:size="70"
			:value="Math.ceil(latestScore * 100)"
			:color="progressColor"
		>
			<v-icon v-if="exercise.position_list === 10" color="black" class="hideWhenHover">🏁</v-icon>
			<v-icon v-else-if="latestScore && latestScore >= 0.8" color="#34e5c8" class="hideWhenHover"
				>mdi-checkbox-marked-circle</v-icon
			>
			<v-icon v-else-if="latestScore && latestScore < 0.8" color="#ff9800" class="hideWhenHover"
				>mdi-alert-circle</v-icon
			>
			<v-icon v-else-if="exercise.status === ExerciseStatus.LOCKED" color="#0000003d">mdi-lock-outline</v-icon>
			<v-icon v-else-if="exercise.status === ExerciseStatus.OPEN" color="#0000003d">mdi-lock-open-outline</v-icon>

			<v-icon
				v-if="latestScore"
				class="scoreLabel"
				size="16"
				:color="latestScore && latestScore >= 0.8 ? '#34e5c8' : '#ff9800'"
				>{{ Math.ceil(latestScore * 100) }}%
			</v-icon>
		</v-progress-circular>
		<div class="infoContainer">
			<div class="infoSubContainer" @click="doExercise(exercise._id)">
				<v-toolbar-title class="title">{{ exercise.name }}</v-toolbar-title>
				<v-btn
					v-if="checkAllowDoingExercise()"
					icon
					color="#2dc7ae"
					class="mr-4 playIconExercise"
					@click="doExercise(exercise._id)"
				>
					<v-icon>fas fa-play</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { ExerciseStatus } from '../../../../constants/exercise';
import { checkIfTodayExerciceIsDone } from '@/utils/exercise.js';

export default {
	name: 'ExerciseItem',
	data() {
		return {
			ExerciseStatus,
			latestScore: null,
		};
	},
	props: {
		exercise: Object,
		isLast: Boolean,
		formation: Object,
		setLoading: { type: Function },
	},
	created() {
		this.latestScore = this.findLatestScore();
	},
	computed: {
		progressColor() {
			if (this.latestScore == null) return '#d8d8d8';
			if (this.latestScore >= 0.8) return '#34e5c8';
			return '#ff9800';
		},
		verticalLineBackground() {
			if (this.latestScore && this.latestScore >= 0.8) return 'passedColor';
			if (this.latestScore && this.latestScore < 0.8) return 'notPassedColor';
			if (this.isLast) return '';
			else return 'disabledColor';
		},
	},
	methods: {
		checkAllowDoingExercise() {
			if (
				[ExerciseStatus.ONGOING, ExerciseStatus.OPEN].includes(this.exercise.status) &&
				this.formation.formation_finished
			)
				return false;
			if (this.exercise.status === ExerciseStatus.LOCKED) return false;
			if ([ExerciseStatus.ONGOING, ExerciseStatus.PASSED].includes(this.exercise.status)) return true;
			if (checkIfTodayExerciceIsDone(this.formation) === false) return true;
			return this.formation.infinity_exercice;
		},
		doExercise(exerciseId) {
			if (this.checkAllowDoingExercise() == false) return;

			this.setLoading(true);
			this.loading = true;
			const urlTarget = `/exercices/${this.formation._id}/${exerciseId}`;
			if (
				this.exercise.usersScores &&
				this.exercise.usersScores.length > 0 &&
				process.env.VUE_APP_MIXPANEL == 'production'
			) {
				this.$mixpanel.track('dashboard-profile-replayExercice');
			}
			setTimeout(() => {
				this.setLoading(false);
				return this.$router.push(urlTarget);
			}, 2000);
		},
		findLatestScore() {
			if (this.exercise.usersScores.length === 0) return null;
			const clone = [...this.exercise.usersScores];
			clone.sort((a, b) => new Date(b.update_date) - new Date(a.update_date));
			return clone[0].score;
		},
	},
};
</script>

<style lang="scss" scoped>
.exercise-card-item {
	margin-bottom: 25px;

	.infoContainer {
		width: 100%;
		padding: 5px 0;
	}

	.infoSubContainer {
		padding: 5px 0;
		border: 2px solid white;
		border-radius: 0px 4px 4px 0px;
		border-left: none;
		transform: translate(-7px, 5px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 -5px 7px -5px #8189a933, 5px 0 7px -5px #8189a933, 0 5px 7px -5px #8189a933;
		cursor: pointer;
	}

	.infoSubContainer:hover {
		color: #34e5c8;
		transition: color 200ms linear;
		-webkit-transition: color 200ms linear;
		-ms-transition: color 200ms linear;
		cursor: pointer;
	}

	.infoSubContainer {
		&:hover {
			color: #34e5c8;
			transition: color 200ms linear;
			-webkit-transition: color 200ms linear;
			-ms-transition: color 200ms linear;
			cursor: pointer;

			.playIconExercise {
				transform: rotate(360deg);
				-webkit-transform: rotate(360deg);
			}
		}
	}

	.progressCircle {
		background-color: white;
		position: relative;
		:hover {
			.scoreLabel {
				display: block;
				font-style: normal;
				font-weight: bold;
			}
			.hideWhenHover {
				display: none;
			}
		}
	}

	.verticalLine {
		::after {
			z-index: 1;
			content: '';
			position: absolute;
			width: 5px;
			height: 34px;
			top: 94%;
		}

		@media screen and (max-width: 500px) {
			::after {
				height: 37px;
				top: 91%;
			}
		}
		@media screen and (max-width: 400px) {
			::after {
				height: 37px;
				top: 92%;
			}
		}
	}
	.verticalLine.disabledColor {
		::after {
			background-color: #d8d8d8;
		}
	}
	.verticalLine.passedColor {
		::after {
			background-color: #34e5c8;
		}
	}
	.verticalLine.notPassedColor {
		::after {
			background-color: #ff9800;
		}
	}
	.title {
		margin-left: 20px;
	}
	.scoreLabel {
		display: none;
	}
}
</style>
